<template>
  <div class="bottom-navbar version-sp">
    <div class="margin-content">
      <div class="container px-0">
        <div
          class="row"
          :class="
            type == 0
              ? 'listDefault0'
              : type == 1
              ? 'listDefault1'
              : type == 2
              ? 'listDefault2'
              : 'listDefault3'
          "
        >
          <div
            class="list-menu-bottom col-12 my-3"
            v-bind:class="{
              'show-dot':
                (type == 0
                  ? listDefault0.length
                  : type == 1
                  ? listDefault1.length
                  : type == 2
                  ? listDefault2.length
                  : listDefault3.length) > 0,
            }"
          >
            <VueSlickCarousel
              v-bind="settingsSlick"
              ref="carousel"
              :dots="true"
              :key="refresh"
            >
              <div
                class="item-menu my-auto text-center"
                v-for="(value, index) in type == 0
                  ? listDefault0
                  : type == 1
                  ? listDefault1
                  : type == 2
                  ? listDefault2
                  : listDefault3"
                :key="index"
                @click="showModal(value)"
              >
                <img
                  v-if="isImage(value.icon)"
                  :src="value.icon"
                  alt=""
                  class="mx-auto img-slick position-relative"
                  style="width: 30px"
                />
                <b-icon v-else :icon="`${value.icon}`" font-scale="2.2" />
                <p class="mb-0 title-menu">{{ value.page_title }}</p>
                <span
                  v-if="
                    value.page_title == 'カート' &&
                    is_login === true &&
                    countListCart > 0
                  "
                  class="sidebar-menu-item-cart position-absolute"
                >
                  {{ countListCart }}
                </span>
                <span
                  v-if="
                    value.page_title == 'コンテンツ一覧' &&
                    (type == 0 || type == 1) &&
                    is_login === true &&
                    countUnread > 0
                  "
                  class="sidebar-menu-item-cart position-absolute"
                >
                  {{ countUnread }}
                </span>
              </div>
            </VueSlickCarousel>
          </div>
        </div>
      </div>
    </div>
    <!-- modal-filter -->
    <div>
      <b-modal
        id="modal-filter"
        no-close-on-backdrop
        hide-header
        hide-footer
        :size="'xl'"
      >
        <div class="d-flex justify-content-between title-modal-filter">
          <div
            class="w-50 custom-tab bottomMenu"
            @click="changeStatusActive('library')"
            :class="{ active: isActiveLibrary }"
          >
            コンテンツ
          </div>
          <div
            class="w-50 custom-tab bottomMenu"
            @click="changeStatusActive('store')"
            :class="{ active: isActiveStore }"
          >
            ショップ
          </div>
        </div>
        <div class="body-modal-filter">
          <label>検索カテゴリ</label>
          <b-form-select
            class="mb-3 w-100"
            v-model="idCategory"
            :options="isActiveLibrary ? listCategoryLibrary : listCategoryStore"
            placeholder="アンケートを選んでください"
          ></b-form-select>
          <div
            class="default-layout"
            style="min-height: 0; margin-bottom: 1rem"
          >
            <div class="header-search d-flex align-items-center">
              <b-form-input
                class="search-input"
                placeholder="キーワードを入力してください(任意)"
                v-model="keyword"
              />
              <b-icon class="search-icon" icon="search" />
            </div>
          </div>
          <div style="text-align: right">
            <b-button
              class=""
              variant="secondary"
              @click="$bvModal.hide('modal-filter')"
            >
              閉じる
            </b-button>
            <b-button class="btn-info" @click="searchContent()">
              <b-spinner
                v-if="isLoading"
                :disabled="isLoading"
                small
              ></b-spinner>
              検索する
            </b-button>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { Routes } from "../../utils/routes";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import icon_home from "@/assets/img/icon_home_bottom.svg";
import icon_content_list from "@/assets/img/icon_content_list_bottom.png";
import icon_shop from "@/assets/img/icon_shop_bottom.png";
import icon_menu from "@/assets/img/icon_menu_bottom.png";
import icon_search from "@/assets/img/icon_search_bottom.png";
import icon_cart from "@/assets/img/icon_cart_bottom.jpg";
import icon_back from "@/assets/img/icon_back_bottom.svg";
import icon_chat from "@/assets/img/icon_chat_bottom.jpg";
import icon_next from "@/assets/img/icon_next_bottom.svg";
import { Api } from "../../utils/http-common";
import { Urls } from "../../utils/urls";
import { Constants } from "../../utils/constants";
export default {
  name: "BottomMenu",
  data() {
    return {
      isActiveTabLibrary: false,
      beginUrl: this.$route.params.shopId
        ? "/" + Routes.SHOP + "/" + this.$route.params.shopId
        : "",
      settingsSlick: {
        dots: true,
        infinite: false,
        slidesToShow: Constants.BOTTOM_SLICK,
        slidesToScroll: Constants.BOTTOM_SLICK,
        initialSlide: 0,
        autoplay: false,
        arrows: true,
      },
      type: 0,
      urlNext: "",
      listDefault0: [
        {
          page_title: "ホーム",
          url: this.$router.resolve({
            name: this.$route.params.shopId ? "library" : "library domain",
          }).href,
          icon: icon_home,
          indType: 0,
        },
        {
          page_title: "コンテンツ一覧",
          url: this.$router.resolve({
            name: this.$route.params.shopId ? "library" : "library domain",
          }).href,
          icon: icon_content_list,
          indType: 0,
        },
        {
          page_title: "ショップ",
          url: this.$router.resolve({
            name: this.$route.params.shopId ? "store" : "store domain",
          }).href,
          icon: icon_shop,
          indType: 1,
        },
        {
          page_title: "メニュー",
          url: "",
          icon: icon_menu,
          indType: 0,
        },
        {
          page_title: "検索",
          url: "",
          icon: icon_search,
          indType: 0,
        },
      ],
      listDefault1: [
        {
          page_title: "ホーム",
          url: this.$router.resolve({
            name: this.$route.params.shopId ? "library" : "library domain",
          }).href,
          icon: icon_home,
          indType: 0,
        },
        {
          page_title: "コンテンツ一覧",
          url: this.$router.resolve({
            name: this.$route.params.shopId ? "library" : "library domain",
          }).href,
          icon: icon_content_list,
          indType: 0,
        },
        {
          page_title: "カート",
          url: this.$router.resolve({
            name: this.$route.params.shopId
              ? "default cart"
              : "default cart domain",
          }).href,
          icon: icon_cart,
          indType: 1,
        },
        {
          page_title: "メニュー",
          url: "",
          icon: icon_menu,
          indType: 1,
        },
        {
          page_title: "検索",
          url: "",
          icon: icon_search,
          indType: 1,
        },
      ],
      listDefault2: [
        {
          page_title: "コンテンツ一覧",
          url: this.$router.resolve({
            name: this.$route.params.shopId ? "library" : "library domain",
          }).href,
          icon: icon_content_list,
          indType: 0,
        },
        {
          page_title: "戻る",
          url: null,
          icon: icon_back,
          indType: 2,
        },
        {
          page_title: "ノート/質問",
          url: "",
          icon: icon_chat,
          indType: 2,
        },
        {
          page_title: "次へ",
          url: null,
          icon: icon_next,
          indType: 2,
        },
        {
          page_title: "目次",
          url: "",
          icon: icon_menu,
          indType: 2,
        },
      ],
      listDefault3: [
        {
          page_title: "コンテンツ一覧",
          url: this.$router.resolve({
            name: this.$route.params.shopId ? "library" : "library domain",
          }).href,
          icon: icon_content_list,
          indType: 0,
        },
        {
          page_title: "ショップ",
          url: this.$router.resolve({
            name: this.$route.params.shopId ? "store" : "store domain",
          }).href,
          icon: icon_shop,
          indType: 1,
        },
        {
          page_title: "メニュー",
          url: "",
          icon: icon_menu,
          indType: 3,
        },
        {
          page_title: "検索",
          url: "",
          icon: icon_search,
          indType: 3,
        },
      ],
      checkPush0: false,
      checkPush1: false,
      checkPush2: false,
      checkPush3: false,
      dataMenu1: [],
      countListCart: 0,
      countUnread: 0,
      is_login:
        localStorage.getItem(Constants.TOKEN_USER) ||
        localStorage.getItem(Constants.TOKEN_ADMIN)
          ? true
          : false,
      refresh: 0,
      inquiry_URL: "",
      formCategory: {
        shop_id: localStorage.getItem(Constants.SHOP_ID),
        page: 1,
      },
      idCategory: 0,
      isActiveLibrary: true,
      isActiveStore: false,
      keyword: "",
      listCategoryStore: [{ text: "すべて", value: 0 }],
      listCategoryLibrary: [{ text: "すべて", value: 0 }],
      isLoading: false,
      shop_id: null,
      shopId: null,
      listPage: [],
      idChapterView: null,
      idPageView: null,
      idPosion: null,
      idContent: null,
    };
  },
  components: { VueSlickCarousel },
  async created() {
    if (this.is_login) {
      if (this.$route.params.idPage) this.type = 2;
      else if (this.$route.name.includes("library")) this.type = 0;
      else if (this.$route.fullPath.includes("store")) this.type = 1;
      else this.type = 3;
      this.refresh++;
      let shopId = localStorage.getItem(Constants.SHOP_ID);
      // let body = {
      //   domain: Constants.DOMAIN,
      //   shop_id: this.$route.params.shopId || null,
      // };
      // let response = await this.$store.dispatch("getShopById", body);
      // this.shopId = response.data.id;
      shopId = this.shopId;
      this.shop_id = shopId;
      // const request = {
      //   shop_id: shopId,
      //   type: this.type != null ? this.type : 3,
      // };
      // this.getUnreadContent(request);
      // this.getBottomSettingType(request);
      if (this.$route.params.idContent) {
        const reqData = {
          id: this.$route.params.idContent,
          shop_id: this.shop_id,
        };
        this.getContentLibraryById(reqData);
      }
      // this.getListSettingPageHeader(shopId);
      // this.getListBottom(request);
      // this.getListSettingShopUser({
      //   domain: Constants.DOMAIN,
      //   shop_id: shopId,
      // });
      this.getListCategoryInStore(this.formCategory);
      this.getListLibraryCategory(this.formCategory);
    }
  },
  computed: {
    ...mapGetters([
      "detailShop",
      "ListSettingShop",
      "listBottom",
      "contentLibraryById",
      "listSettingPageHeader",
      "listCart",
      "showMenu",
      "countContentUnread",
      "message",
      "success",
      "error",
      "isShowButton",
      "listCategory",
      "listLibraryCategory",
    ]),
  },
  watch: {
    detailShop() {
      this.shopId = this.detailShop.id;
    },
    listLibraryCategory() {
      const self = this;
      if (Object.keys(this.listLibraryCategory).length > 0) {
        this.listLibraryCategory.forEach((item) => {
          if (
            self.listCategoryLibrary.length <
            self.listLibraryCategory.length + 1
          ) {
            self.listCategoryLibrary.push({
              text: item.category_name,
              value: item.id,
            });
          }
        });
      }
    },
    listCategory() {
      const self = this;
      if (Object.keys(this.listCategory).length > 0) {
        this.listCategory.data.forEach((item) => {
          if (
            self.listCategoryStore.length <
            self.listCategory.data.length + 1
          ) {
            self.listCategoryStore.push({
              text: item.category_name,
              value: item.id,
            });
          }
        });
      }
    },
    countContentUnread() {
      this.countUnread = this.countContentUnread;
    },
    listBottom() {
      if (
        this.listBottom &&
        this.listBottom.length > 0 &&
        !this["checkPush" + this.type]
      ) {
        this.listBottom.forEach((element) => {
          var item = {
            page_title: element.page_title,
            url: this.beginUrl + element.url,
            icon: element.icon,
            indType: 3,
            type: element.type,
            fixed_page_id: element.fixed_page_id,
            type_fixed: element.type_fixed,
          };
          if (item.type == this.type)
            this["listDefault" + this.type].push(item);
        });
      }
      this.$refs.carousel.goTo(0);
      this.refresh++;
      if (this["listDefault" + this.type].length < Constants.BOTTOM_SLICK) {
        this.settingsSlick.slidesToShow =
          this["listDefault" + this.type].length;
        this.settingsSlick.slidesToScroll =
          this["listDefault" + this.type].length;
      } else {
        this.settingsSlick.slidesToShow = Constants.BOTTOM_SLICK;
        this.settingsSlick.slidesToScroll = Constants.BOTTOM_SLICK;
      }
      this["checkPush" + this.type] = true;
    },
    contentLibraryById() {
      if (
        this.contentLibraryById &&
        this.contentLibraryById.length > 0 &&
        this.contentLibraryById[0].catalogue.length > 0
      ) {
        this.listDefault2[4].url = this.$router.resolve({
          name: this.$route.params.shopId
            ? "DetailContentLibrary"
            : "DetailContentLibrary domain",
          params: {
            idContent: this.$route.params.idContent,
          },
        }).href;
        if (
          this.$route.params.idPage &&
          this.$route.params.idPage <
            this.contentLibraryById[0].catalogue.length
        ) {
          this.listDefault2[3].url = this.$router.resolve({
            name: this.$route.params.shopId
              ? "DetailPageLibrary"
              : "DetailPageLibrary domain",
            params: {
              idPage: Number(this.$route.params.idPage) + 1,
              idContent: this.$route.params.idContent,
            },
          }).href;
        }
        if (this.$route.params.idPage && this.$route.params.idPage > 0) {
          this.listDefault2[1].url = this.$router.resolve({
            name: this.$route.params.shopId
              ? "DetailPageLibrary"
              : "DetailPageLibrary domain",
            params: {
              idPage: Number(this.$route.params.idPage) - 1,
              idContent: this.$route.params.idContent,
            },
          }).href;
        }
      }
      if (this.contentLibraryById && this.contentLibraryById.length > 0) {
        this.contentLibraryById[0].catalogue.forEach((element) => {
          const objectPage = {
            idChapter: null,
            page: [],
            position: null,
          };
          if (element.page && element.page.length > 0) {
            element.page.forEach((item) => {
              objectPage.page.push(item.id);
            });
          } else {
            objectPage.page.push(element.id);
          }
          objectPage.position = element.position;
          objectPage.idChapter = element.catalogue_id;
          this.listPage.push(objectPage);
        });
      }
    },
    listSettingPageHeader() {
      this.dataMenu1 = this.listSettingPageHeader;
    },
    listCart() {
      this.countListCart = this.listCart.length;
      // this.listCart.forEach((element) => {
      //   element.list_cart.forEach(() => {
      //     this.countListCart += 1;
      //   });
      // });
    },
    $route() {
      if (this.$route.params.idPage) this.type = 2;
      else if (this.$route.name.includes("library")) this.type = 0;
      else if (this.$route.fullPath.includes("store")) this.type = 1;
      else this.type = 3;
      this.$refs.carousel.goTo(0);
      this.refresh++;
      if (this["listDefault" + this.type].length < 5) {
        this.settingsSlick.slidesToShow =
          this["listDefault" + this.type].length;
        this.settingsSlick.slidesToScroll =
          this["listDefault" + this.type].length;
      } else {
        this.settingsSlick.slidesToShow = 5;
        this.settingsSlick.slidesToScroll = 5;
      }
    },
    ListSettingShop() {
      if (this.ListSettingShop.length > 0) {
        this.inquiry_URL = this.ListSettingShop[0].url_qa;
      }
    },
  },
  methods: {
    ...mapActions({
      // getBottomSettingType: "getBottomSettingType",
      getContentLibraryById: "getContentLibraryById",
      getListSettingPageHeader: "getListSettingPageHeader",
      getListCart: "getListCart",
      getUnreadContent: "getUnreadContent",
      getListBottom: "getListBottom",
      // getListSettingShopUser: "getListSettingShopUser",
      getListCategoryInStore: "getListCategoryInStore",
      getListLibraryCategory: "getListLibraryCategory",
    }),
    isImage(url) {
      return /\.(jpg|jpeg|png|svg|JPG|JPEG|PNG)$/.test(url);
    },
    returnPage(value) {
      if (value.indType == null) value.indType = 3;
      this.type = value.indType;
      const request = {
        shop_id: localStorage.getItem(Constants.SHOP_ID),
        type: this.type,
      };
      // this.getBottomSettingType(request);
      this.getListBottom(request);
      this.getUnreadContent(request);
      this.$refs.carousel.goTo(0);
      this.refresh++;
      if (this["listDefault" + this.type].length <= Constants.BOTTOM_SLICK) {
        this.settingsSlick.slidesToShow =
          this["listDefault" + this.type].length;
        this.settingsSlick.slidesToScroll =
          this["listDefault" + this.type].length;
      } else {
        this.settingsSlick.slidesToShow = Constants.BOTTOM_SLICK;
        this.settingsSlick.slidesToScroll = Constants.BOTTOM_SLICK;
      }
      if (value.page_title == "メニュー") {
        this.$store.commit("set", ["showMenu", !this.showMenu]);
      } else {
        // if (
        //   value.type_fixed &&
        //   value.type_fixed == Constants.FIXED_PAGE_TYPE["column_page"]
        // ) {
        //   this.$router.push({
        //     name: this.$route.params.shopId
        //       ? "pageColumn"
        //       : "pageColumn domain",
        //     params: {
        //       id: value.fixed_page_id,
        //     },
        //   });
        // } else {
        if (value.page_title == "お問い合わせ") {
          if (this.inquiry_URL) {
            window.location.href = this.inquiry_URL;
          } else {
            if (this.$route.params.shopId) {
              this.$router.push({
                name: "create inquiry user",
                params: { shopId: this.shopId },
              });
            } else {
              this.$router.push({
                name: "create inquiry user domain",
              });
            }
          }
        } else {
          if (this.$router.history.current.path == value.url) return;
          this.$router.push(value.url);
        }
        // }
        this.$store.commit("set", ["showMenu", false]);
      }
    },
    changeStatusActive(active) {
      if (active === "store") {
        this.isActiveStore = true;
        this.isActiveLibrary = false;
      } else if (active === "library") {
        this.isActiveStore = false;
        this.isActiveLibrary = true;
      }
    },
    showModal(value) {
      if (value.page_title === "検索") {
        this.$store.commit("set", ["isShowButton", false]);
        this.$bvModal.show("modal-filter");
      } else if (value.page_title === "ノート/質問") {
        this.$store.commit("set", ["isShowButton", !this.isShowButton]);
      } else if (value.page_title === "次へ") {
        this.$store.commit("set", ["isShowButton", false]);
        this.NextPage();
      } else if (value.page_title === "戻る") {
        this.$store.commit("set", ["isShowButton", false]);
        this.PrevPage();
      } else if (value.page_title === "ログアウト") {
        this.$store.commit("set", ["isShowButton", false]);
        this.logoutBtn();
      } else {
        this.$store.commit("set", ["isShowButton", false]);
        this.returnPage(value);
        if (value.page_title === "コンテンツ一覧") {
          this.changeStatusActive("library");
        } else if (value.page_title === "ショップ") {
          this.changeStatusActive("store");
        }
      }
    },
    searchContent() {
      this.isLoading = true;
      const shop_id = localStorage.getItem(Constants.SHOP_ID);
      // const self = this;
      // const formData = {
      //   keyword: this.keyword,
      //   author_id: "",
      //   category_id: [this.idCategory],
      //   shop_id: shop_id,
      //   is_search_favorite: "",
      //   is_search_recent_content: "",
      //   is_search_new_content: "",
      //   limit: "8",
      // };
      const shopId = this.$route.params.shopId;
      if (this.isActiveLibrary) {
        // Api.userRequestServer
        //   .post(`/${Urls.LIBRARY}/${Urls.FILTER}`, formData)
        //   .then((response) => {
        // const { data } = response;
        const keyword = this.keyword;
        const idCategory = this.idCategory;
        // if (data.success) {
        this.$bvModal.hide("modal-filter");
        this.isLoading = false;
        if (shopId) {
          if (keyword && !idCategory) {
            this.$router
              .push({
                name: "library",
                params: {
                  shopId: shop_id,
                },
                query: {
                  keyword: this.keyword,
                },
              })
              .catch(() => {
                this.$router
                  .push({
                    name: "store",
                  })
                  .then(() => {
                    this.$router.push({
                      name: "library",
                      params: {
                        shopId: shop_id,
                      },
                      query: {
                        keyword: keyword,
                      },
                    });
                  });
              });
          } else if (!keyword && idCategory) {
            this.$router
              .push({
                name: "library",
                params: {
                  shopId: shop_id,
                },
                query: {
                  categoryId: this.idCategory,
                },
              })
              .catch(() => {
                this.$router
                  .push({
                    name: "store",
                  })
                  .then(() => {
                    this.$router.push({
                      name: "library",
                      params: {
                        shopId: shop_id,
                      },
                      query: {
                        categoryId: idCategory,
                      },
                    });
                  });
              });
          } else if (idCategory && keyword) {
            this.$router
              .push({
                name: "library",
                params: {
                  shopId: shop_id,
                },
                query: {
                  keyword: this.keyword,
                  categoryId: this.idCategory,
                },
              })
              .catch(() => {
                this.$router
                  .replace({
                    name: "store",
                  })
                  .then(() => {
                    this.$router.push({
                      name: "library",
                      params: {
                        shopId: shop_id,
                      },
                      query: {
                        keyword: keyword,
                        categoryId: idCategory,
                      },
                    });
                  });
              });
          }
        } else {
          if (keyword && !idCategory) {
            this.$router
              .push({
                name: "library domain",
                query: {
                  keyword: this.keyword,
                },
              })
              .catch(() => {
                this.$router
                  .push({
                    name: "store domain",
                  })
                  .then(() => {
                    this.$router.push({
                      name: "library domain",
                      query: {
                        keyword: keyword,
                      },
                    });
                  });
              });
          } else if (!keyword && idCategory) {
            this.$router
              .push({
                name: "library domain",
                query: {
                  categoryId: this.idCategory,
                },
              })
              .catch(() => {
                this.$router
                  .push({
                    name: "store domain",
                  })
                  .then(() => {
                    this.$router.push({
                      name: "library domain",
                      query: {
                        categoryId: idCategory,
                      },
                    });
                  });
              });
          } else if (idCategory && keyword) {
            this.$router
              .push({
                name: "library domain",
                query: {
                  keyword: this.keyword,
                  categoryId: this.idCategory,
                },
              })
              .catch(() => {
                this.$router
                  .replace({
                    name: "store domain",
                  })
                  .then(() => {
                    this.$router.push({
                      name: "library domain",
                      query: {
                        keyword: keyword,
                        categoryId: idCategory,
                      },
                    });
                  });
              });
          }
        }
        this.idCategory = 0;
        this.keyword = "";
        // } else {
        //   self.$bvModal.hide("modal-filter");
        //   self.isLoading = false;
        //   self.$toasted.error(data.message);
        // }
        // })
        // .catch((error) => {
        //   self.$bvModal.hide("modal-filter");
        //   self.isLoading = false;
        //   self.$toasted.error(error.message);
        // });
      } else {
        // Api.userRequestServer
        //   .post(`/${Urls.STORE}/${Urls.FILTER}`, formData)
        //   .then((response) => {
        //     const { data } = response;
        const keyword = this.keyword;
        const idCategory = this.idCategory;
        // if (data.success) {
        this.$bvModal.hide("modal-filter");
        this.isLoading = false;
        if (shopId) {
          if (keyword && !idCategory) {
            this.$router
              .push({
                name: "store",
                params: {
                  shopId: shop_id,
                },
                query: {
                  keyword: this.keyword,
                },
              })
              .catch(() => {
                this.$router
                  .push({
                    name: "library",
                  })
                  .then(() => {
                    this.$router.push({
                      name: "store",
                      params: {
                        shopId: shop_id,
                      },
                      query: {
                        keyword: keyword,
                      },
                    });
                  });
              });
          } else if (idCategory && !keyword) {
            this.$router
              .push({
                name: "store",
                params: {
                  shopId: shop_id,
                },
                query: {
                  categoryId: this.idCategory,
                },
              })
              .catch(() => {
                this.$router
                  .push({
                    name: "library",
                  })
                  .then(() => {
                    this.$router.push({
                      name: "store",
                      params: {
                        shopId: shop_id,
                      },
                      query: {
                        categoryId: idCategory,
                      },
                    });
                  });
              });
          } else if (idCategory && keyword) {
            this.$router
              .push({
                name: "store",
                params: {
                  shopId: shop_id,
                },
                query: {
                  categoryId: this.idCategory,
                  keyword: this.keyword,
                },
              })
              .catch(() => {
                this.$router
                  .replace({
                    name: "library",
                  })
                  .then(() => {
                    this.$router.push({
                      name: "store",
                      params: {
                        shopId: shop_id,
                      },
                      query: {
                        categoryId: idCategory,
                        keyword: this.keyword,
                      },
                    });
                  });
              });
          }
        } else {
          if (keyword && !idCategory) {
            this.$router
              .push({
                name: "store domain",
                query: {
                  keyword: this.keyword,
                },
              })
              .catch(() => {
                this.$router
                  .push({
                    name: "library domain",
                  })
                  .then(() => {
                    this.$router.push({
                      name: "store domain",
                      query: {
                        keyword: keyword,
                      },
                    });
                  });
              });
          } else if (idCategory && !keyword) {
            this.$router
              .push({
                name: "store domain",
                query: {
                  categoryId: this.idCategory,
                },
              })
              .catch(() => {
                this.$router
                  .push({
                    name: "library domain",
                  })
                  .then(() => {
                    this.$router.push({
                      name: "store domain",
                      query: {
                        categoryId: idCategory,
                      },
                    });
                  });
              });
          } else if (idCategory && keyword) {
            this.$router
              .push({
                name: "store domain",
                query: {
                  categoryId: this.idCategory,
                  keyword: this.keyword,
                },
              })
              .catch(() => {
                this.$router
                  .replace({
                    name: "library domain",
                  })
                  .then(() => {
                    this.$router.push({
                      name: "store domain",
                      query: {
                        categoryId: idCategory,
                        keyword: this.keyword,
                      },
                    });
                  });
              });
          }
        }
        this.keyword = "";
        this.idCategory = 0;
        // } else {
        //   self.$bvModal.hide("modal-filter");
        //   self.isLoading = false;
        //   self.$toasted.error(data.message);
        // }
        // })
        // .catch((error) => {
        //   self.$bvModal.hide("modal-filter");
        //   self.isLoading = false;
        //   self.$toasted.error(error.message);
        // });
      }
    },
    PrevPage() {
      const positionId =
        this.$route.query.position != null
          ? parseInt(this.$route.query.position)
          : null;
      this.listPage.forEach((element, index) => {
        if (element.position === positionId) {
          const indexpage = element.page.indexOf(
            parseInt(this.$route.params.idPage)
          );
          if (indexpage === 0 && index === 0) {
            const IndexChapterMax = this.listPage.length - 1;
            const inddexPageMax =
              this.listPage[IndexChapterMax].page[
                this.listPage[IndexChapterMax].page.length - 1
              ];
            this.idChapterView = this.listPage[IndexChapterMax].idChapter;
            this.idPageView = inddexPageMax;
            this.idPosion = this.listPage[IndexChapterMax].position;
            this.idContent = this.$route.params.idContent;
            return;
            // this.$router.push({
            //   name: "DetailPageLibrary",
            //   params: {
            //     idPage: inddexPageMax,
            //     idContent: this.$route.params.idContent,
            //   },
            //   query: {
            //     idChapter: this.listPage[IndexChapterMax].idChapter,
            //     position: this.listPage[IndexChapterMax].position,
            //   },
            // });
          } else if (indexpage === 0 && index !== 0) {
            const indexChapter = index - 1;
            const indexpage = this.listPage[indexChapter].page.length - 1;
            this.idChapterView = this.listPage[indexChapter].idChapter;
            this.idPageView = this.listPage[indexChapter].page[indexpage];
            this.idPosion = this.listPage[indexChapter].position;
            this.idContent = this.$route.params.idContent;
            return;
            // this.$router.push({
            //   name: "DetailPageLibrary",
            //   params: {
            //     idPage: this.listPage[indexChapter].page[indexpage],
            //     idContent: this.$route.params.idContent,
            //   },
            //   query: {
            //     position: this.listPage[indexChapter].position,
            //     idChapter: this.listPage[indexChapter].idChapter,
            //   },
            // });
          } else {
            const page = indexpage - 1;
            this.idChapterView = element.idChapter;
            this.idPageView = element.page[page];
            this.idPosion = element.position;
            this.idContent = this.$route.params.idContent;
            return;
            // this.$router.push({
            //   name: "DetailPageLibrary",
            //   params: {
            //     idPage: element.page[page],
            //     idContent: this.$route.params.idContent,
            //   },
            //   query: {
            //     idChapter: element.idChapter,
            //     position: element.position,
            //   },
            // });
          }
        }
      });
      this.$router.push({
        name: this.$route.params.shopId
          ? "DetailPageLibrary"
          : "DetailPageLibrary domain",
        params: {
          idPage: this.idPageView,
          idContent: this.idContent,
        },
        query: {
          idChapter: this.idChapterView,
          position: this.idPosion,
        },
      });
    },
    NextPage() {
      const positionId =
        this.$route.query.position != null
          ? parseInt(this.$route.query.position)
          : null;
      this.listPage.forEach((element, index) => {
        if (element.position === positionId) {
          const indexpage = element.page.indexOf(
            parseInt(this.$route.params.idPage)
          );
          if (
            indexpage === element.page.length - 1 &&
            index === this.listPage.length - 1
          ) {
            this.idChapterView = this.listPage[0].idChapter;
            this.idPageView = this.listPage[0].page[0];
            this.idPosion = this.listPage[0].position;
            this.idContent = this.$route.params.idContent;
            return;
            // this.$router.push({
            //   name: "DetailPageLibrary",
            //   params: {
            //     idPage: this.listPage[0].page[0],
            //     idContent: this.$route.params.idContent,
            //   },
            //   query: {
            //     idChapter: this.listPage[0].idChapter,
            //     position: this.listPage[0].position,
            //   },
            // });
          } else if (
            indexpage === element.page.length - 1 &&
            index !== this.listPage.length - 1
          ) {
            const indexChapter = index + 1;
            this.idChapterView = this.listPage[indexChapter].idChapter;
            this.idPageView = this.listPage[indexChapter].page[0];
            this.idPosion = this.listPage[indexChapter].position;
            this.idContent = this.$route.params.idContent;
            // this.$router.push({
            //   name: "DetailPageLibrary",
            //   params: {
            //     idPage: this.listPage[indexChapter].page[0],
            //     idContent: this.$route.params.idContent,
            //   },
            //   query: {
            //     idChapter: this.listPage[indexChapter].idChapter,
            //     position: this.listPage[indexChapter].position,
            //   },
            // });
            return;
          } else {
            const page = indexpage + 1;
            this.idChapterView = element.idChapter;
            this.idPageView = element.page[page];
            this.idPosion = element.position;
            this.idContent = this.$route.params.idContent;
            // this.$router.push({
            //   name: "DetailPageLibrary",
            //   params: {
            //     idPage: element.page[page],
            //     idContent: this.$route.params.idContent,
            //   },
            //   query: {
            //     position: element.position,
            //     idChapter: element.idChapter,
            //   },
            // });
            return;
          }
        }
      });
      this.$router.push({
        name: this.$route.params.shopId
          ? "DetailPageLibrary"
          : "DetailPageLibrary domain",
        params: {
          idPage: this.idPageView,
          idContent: this.idContent,
        },
        query: {
          idChapter: this.idChapterView,
          position: this.idPosion,
        },
      });
    },
    logoutBtn() {
      const shopId = this.shop_id;
      Api.userRequestServer
        .post(Urls.LOGOUT)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            this.$toasted.success(data.message);
            this.$store.commit("set", ["success", false]);
            this.$store.commit("set", ["message", ""]);
            if (localStorage.getItem(Constants.TOKEN_USER)) {
              localStorage.removeItem(Constants.USER_TYPE_USER);
              localStorage.removeItem(Constants.TOKEN_USER);
              localStorage.removeItem(Constants.NORMAL_USER_INFO);
              localStorage.removeItem(Constants.LIST_SLAG);
              localStorage.removeItem(Constants.IS_REMEMBER_USER);
              localStorage.removeItem(Constants.EMAIL_USER);
              localStorage.removeItem(Constants.USER_ID);
            }
            if (this.$route.params.shopId) {
              this.$router.push({
                name: "login shop",
                params: { shopId: shopId },
              });
            } else {
              this.$router.push({
                name: "login shop domain",
              });
            }
          } else {
            this.$toasted.error(data.message);
            this.$store.commit("set", ["message", ""]);
            this.$store.commit("set", ["error", false]);
          }
        })
        .catch((error) => {
          this.$toasted.error(error.message);
          this.$store.commit("set", ["message", ""]);
          this.$store.commit("set", ["error", false]);
        });
    },
  },
};
</script>
<style lang="scss">
.title-modal-filter {
  height: 50px;
  align-items: center;
  background: #02bad2;
  div {
    text-align: center;
    color: #fff;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.body-modal-filter {
  padding: 1rem;
}
.custom-tab.bottomMenu {
  margin-bottom: 0;
  position: relative;
  color: #000 !important;
  border-radius: 0;
  background-color: #f5f5f5 !important;
}
.custom-tab.bottomMenu::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(1);
  height: 2px;
  bottom: 0;
  left: 0;
  // background-color: #8a93a2;
}
.custom-tab.bottomMenu:hover:after {
  transform: scaleX(1);
  transition: transform 0.25s ease-out;
}
.custom-tab.bottomMenu.active {
  color: #000015;
  font-size: 18px !important;
  font-weight: 900 !important;
  background-color: #02bad2 !important;
}
.custom-tab.bottomMenu.active::after {
  // background-color: #000015;
}
.listDefault3 .show-dot .slick-slider .slick-list .slick-track {
  width: 100% !important;
  display: flex !important;
  justify-content: space-evenly !important;
}

.listDefault3 .show-dot .slick-slider .slick-list .slick-track .slick-slide {
  width: 75px !important;
}
</style>
